import { MDXProvider } from "@mdx-js/react"
import React from "react"
import { H1, H2, H3, PM, PXS, InlineImage } from "@src/style/mdxStyle"
import styled, { css } from "styled-components"

export default ({ children }) => (
  <MDXProvider
    components={{
      // Map HTML element tag to React component
      h1: H1,
      h2: H2,
      h3: H3,
      blockquote: styled.div`
        padding-left: 1rem;
        p {
          font-size: 1rem;
          font-style: italic;
        }
      `,
      p: (props) => {
        const { children } = props
        if (
          children &&
          typeof children === "object" &&
          children.props &&
          children.props.className == "gatsby-resp-image-wrapper"
        ) {
          return <InlineImage>{children}</InlineImage>
        }
        return <PM {...props} />
      },
      ul: styled.ul`
        padding: 1rem;
      `,
      li: styled.li`
        ${(props) => props.theme.typography.paragraph.M}
      `,
    }}
  >
    {children}
  </MDXProvider>
)
