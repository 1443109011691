class API {
  static BASE_URL_PROD = "https://us-central1-arcpanel-web.cloudfunctions.net/"
  static BASE_URL_DEV = "http://localhost:5001/arcpanel-web/us-central1/"
  static BASE_URL = API.BASE_URL_PROD

  static getParameterByName(name, url) {
    if (typeof window === `undefined`) {
      return ""
    }

    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, "\\$&")
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")

    var results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ""
    return decodeURIComponent(results[2].replace(/\+/g, " "))
  }

  static getPromiseApi(endpoint, stateChange) {
    stateChange({
      loading: true,
    })
    return fetch(API.BASE_URL + endpoint, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (stateChange) {
          stateChange({
            loading: false,
          })
        }

        return response
      })
      .catch((error) => {
        console.error(error)
        if (stateChange) {
          stateChange({
            loading: false,
          })
        }
        throw error
      })
  }

  static postApi(endpoint, body, stateChange) {
    if (stateChange)
      stateChange({
        loading: true,
      })
    return fetch(API.BASE_URL + endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        if (stateChange) {
          stateChange({
            loading: false,
          })
        }

        return response
      })
      .catch((error) => {
        console.error(error)
        if (stateChange) {
          stateChange({
            loading: false,
          })
        }
        throw error
      })
  }

  static postUserApi(firebaseUser, request, stateChange) {
    // TODO: pass lang to api
    let code = API.getParameterByName("code")
    if (stateChange) {
      stateChange({
        loading: true,
      })
    }
    request.body = Object.assign({}, request.body, {
      email: firebaseUser.email,
      discountCode: code,
    })
    return firebaseUser.getIdToken().then((token) => {
      return fetch(API.BASE_URL + request.endpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(request.body),
      })
        .then((response) => response.json())
        .then((response) => {
          if (stateChange) {
            stateChange({
              loading: false,
            })
          }
          return response
        })
        .catch((error) => {
          console.error(error)
          if (stateChange) {
            stateChange({
              loading: false,
            })
          }
          throw error
        })
    })
  }

  static hasPurchase(firebaseUser, stateChange, callback) {
    return API.postUserApi(
      firebaseUser,
      { endpoint: "pApiV2/hasPurchase" },
      stateChange
    )
  }

  static addPurchase(body, firebaseUser, stateChange, callback) {
    return API.postUserApi(
      firebaseUser,
      { endpoint: "pApiV2/addPurchase", body: body },
      stateChange
    )
  }

  static deactivateSerial(body, firebaseUser, stateChange) {
    return API.postUserApi(
      firebaseUser,
      { endpoint: "pApiV2/deactivateSerial", body: body },
      stateChange
    )
  }

  static preLicenseCodeValid(code, stateChange, callback) {
    return API.getPromiseApi(
      "aApiV2/partnerCodeValid?code=" + code,
      stateChange
    )
  }

  static activatePreLicenseCode(code, lang, firebaseUser, stateChange) {
    return API.postUserApi(
      firebaseUser,
      {
        endpoint: "pApiV2/activatePartnerCode",
        body: { lang: lang, code: code },
      },
      stateChange
    )
  }

  static postReview(review, stateChange) {
    return API.postApi("aApiV3/review", review, stateChange)
  }

  static pricing(code, stateChange) {
    var url = "aApiV2/pricing"
    if (code) {
      url += "?code=" + code
    }
    return API.getPromiseApi(url, stateChange)
  }

  static nameIt(keys, algorithm, stateChange) {
    return API.getPromiseApi(
      `aApi/nameIt?limit=10&keys=${keys}&algorithm=${algorithm}`,
      stateChange
    )
  }

  static mailPromoSubscribe(email, stateChange) {
    return API.getPromiseApi(
      `aApi/mailPromoSubscribe?email=${email}`,
      stateChange
    )
  }

  static downloadFree(stateChange) {
    return API.getPromiseApi("aApiV2/downloadFree", stateChange)
  }

  static createStripeSession(code, firebaseUser, stateChange) {
    return API.postUserApi(
      firebaseUser,
      { endpoint: "aApiV2/create-stripe-session", body: { code: code } },
      stateChange
    )
  }
}

export default API
