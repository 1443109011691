import React, { useEffect, useState } from 'react'

import API from '@src/common/api.js'
// import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import MyMDXLayout from '@components/layout/mdxLayout'

import TOC from '@components/blog/toc'

import {
  FullScreenPostWrapper,
  GlobalWidthLimiter,
  BlogPostBody,
  StickyNav,
  StickyWrapper,
  TableOfContentsWrapper
} from '@src/style/mdxStyle'

export default props => {[]
  const { data } = props
  const { mdx } = data
  const [code, setCode] = useState('')
  useEffect(() => {
    const urlCode = API.getParameterByName('code')
    setCode(urlCode || '')
  }, []) // componentDidMount

  return (
    <MyMDXLayout>
      <StickyNav defaultIndex={0} code={code} membersArea />
      <FullScreenPostWrapper>
        <GlobalWidthLimiter>
          <BlogPostBody>
            <section key={mdx.internal.contentFilePath}>
              <TableOfContentsWrapper>
                <StickyWrapper>
                  <TOC items={mdx.tableOfContents.items} />
                </StickyWrapper>
              </TableOfContentsWrapper>

              <>{props.children}</>
            </section>
          </BlogPostBody>
        </GlobalWidthLimiter>
      </FullScreenPostWrapper>
    </MyMDXLayout>
  )
}
