import React, { Fragment } from 'react'

import { TableOfContentsWrapper, TableOfContents } from '@src/style/mdxStyle'
import { FormattedMessage } from 'react-intl'

const buildTocLevel = items => {
  return (
    <ul>
      {items.map((item, index) => {
        return (
          <li key={index}>
            <a href={item.url}>{item.title}</a>
            {item.items && buildTocLevel(item.items)}
          </li>
        )
      })}
    </ul>
  )
}

export default ({ items }) => {
  if (!items) {
    return
  }
  return (
    <TableOfContents>{buildTocLevel(items)}</TableOfContents>
  )
}
