import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FrequencySeparationType, TexturePreviewMode } from '../common/consts'
import { HintButton } from '../common/index'
import Slider from '../common/slider/normalSlider'
import { format, FormattedMessage } from '../common/style/formattedMessage'
import * as Style from '../common/style/panelStyle.js'
import { useARCRetouchLogic } from '../../common/uxp/shared/arcretouch_logic'
import { useGlobalState } from '../../common/uxp/state'

const SLIDER_MIN = 0
const SLIDER_MAX = 40
const SLIDER_START = 4
const SLIDER_STEP = 0.1

export default () => {
  const { logic } = useARCRetouchLogic()
  const { state } = useGlobalState()
  const [blurType, setBlurType] = useState(FrequencySeparationType.GAUSSIAN)
  const sliderRef = useRef(null)

  const [settings, setSettings] = useState({
    radius: 4,
    initialSet: false,
    texturePreviewMode: TexturePreviewMode.NORMAL
  })

  const _onSet = useCallback(
    async values => {
      console.log('onSet', settings, values)
      const newRadius = parseFloat(values[0])
      setSettings({
        ...settings,
        radius: newRadius
      })
      logic && await logic.onNewFrequency_update(newRadius, settings.texturePreviewMode)
    },
    [logic, settings.radius, settings.initialSet, settings.texturePreviewMode]
  )

  const _onUpdate = useCallback(
    async values => {
      console.log('onUpdate', settings, values)
      const newRadius = parseFloat(values[0])
      if (!settings.initialSet) {
        logic && await logic.onNewFrequency_update(
          newRadius,
          settings.texturePreviewMode
        )
      }
      setSettings({
        ...settings,
        radius: newRadius,
        initialSet: true
      })
    },
    [logic, settings.radius, settings.initialSet, settings.texturePreviewMode]
  )

  const _onCancel = useCallback(async () => {
    console.log('onCancel', settings)
    logic && await logic.onNewFrequency_cancel()
    setSettings({
      radius: 4,
      initialSet: false,
      texturePreviewMode: TexturePreviewMode.NORMAL
    })
  }, [settings.radius, settings.initialSet])

  const _onApply = useCallback(async () => {
    console.log('onApply', settings)

    logic && await logic.onNewFrequency_apply()
    setSettings({
      radius: 4,
      initialSet: false,
      texturePreviewMode: TexturePreviewMode.NORMAL
    })
  }, [settings.radius, settings.initialSet])

  useEffect(() => {
    if (sliderRef && sliderRef.current) sliderRef.current.set(settings.radius)
  }, [sliderRef, settings.texturePreviewMode])

  return (
    <>
      <Style.Row>
        <Style.Col>
          <Style.Header>
            <FormattedMessage id='arcretouch.frequency_separation' />
          </Style.Header>
        </Style.Col>
      </Style.Row>
      <Style.Row>
        <Style.Col>
          {state.arcretouch.comingSoon && <></>}
          {!state.arcretouch.comingSoon && (
            <Style.SelectWrapper>
              <Style.Select
                dir='rtl'
                disabled={state.frequency.started}
                value={blurType}
                onChange={e => {
                  setBlurType(e.target.value)
                }}
              >
                <Style.SelectOption value={FrequencySeparationType.GAUSSIAN}>
                  {format({
                    id: 'arcretouch.frequency_separation_gaussian'
                  })}
                </Style.SelectOption>
                <Style.SelectOption value={FrequencySeparationType.SURFACE}>
                  {format({
                    id: 'arcretouch.frequency_separation_surface'
                  })}
                </Style.SelectOption>
                <Style.SelectOption value={FrequencySeparationType.MEDIAN}>
                  {format({
                    id: 'arcretouch.frequency_separation_median'
                  })}
                </Style.SelectOption>
              </Style.Select>
            </Style.SelectWrapper>
          )}
        </Style.Col>
        <Style.Col>
          <HintButton
            disabled={state.frequency.started}
            id='arcmask.build'
            onClick={async () => {
              logic && await logic.onNewFrequency_start(blurType)
            }}
          />
        </Style.Col>
      </Style.Row>
      {state.frequency.started && (
        <Style.Overlay top='90px'>
          <Style.Absolute>
            <Style.Row>
              <Style.Col>
                <Style.ExtraSliderEntry>
                  <Style.ExtraSliderRow>
                    <Style.ExtraSliderTitle>
                      <FormattedMessage id='arcretouch.texture_selection' />
                    </Style.ExtraSliderTitle>
                    <Style.Squares>
                      <Style.GreySquare
                        onClick={async () => {
                          setSettings({
                            ...settings,
                            texturePreviewMode: TexturePreviewMode.NORMAL
                          })
                        }}
                      />
                      <Style.WhiteSquare
                        onClick={async () => {
                          setSettings({
                            ...settings,
                            texturePreviewMode: TexturePreviewMode.WHITE
                          })
                        }}
                      />
                      <Style.BlackSquare
                        onClick={async () => {
                          setSettings({
                            ...settings,
                            texturePreviewMode: TexturePreviewMode.BLACK
                          })
                        }}
                      />
                    </Style.Squares>
                    <Style.ExtraSliderValue>
                      {(
                        Math.round(
                          ((settings.radius * 100) / SLIDER_MAX) * 100
                        ) / 100
                      ).toFixed(2)}
                      %
                    </Style.ExtraSliderValue>
                  </Style.ExtraSliderRow>
                  <Style.ExtraSlider>
                    <Style.SmallButton
                      onClick={async () => {
                        const currentValue = parseFloat(sliderRef.current.get())
                        const steps = sliderRef.current.getSteps()[0]
                        console.log('-', steps)
                        if (steps.length > 0) {
                          const stepDown = steps[0]
                          const newValue = currentValue - stepDown
                          console.log(
                            '- currentValue',
                            currentValue,
                            'stepDown',
                            stepDown
                          )
                          sliderRef.current.set(newValue)
                        }
                      }}
                    >
                      -
                    </Style.SmallButton>
                    <Slider
                      ref={sliderRef}
                      range={{
                        min: [0, 0.01],
                        '20%': [4, 0.05],
                        '35%': [7, 0.1],
                        '70%': [10, 1],
                        max: [SLIDER_MAX]
                      }}
                      start={SLIDER_START}
                      onSet={_onSet}
                      onChange={_onSet}
                      onUpdate={_onUpdate}
                    />
                    <Style.SmallButton
                      onClick={async () => {
                        const currentValue = parseFloat(sliderRef.current.get())
                        const steps = sliderRef.current.getSteps()[0]
                        console.log('+', steps)
                        if (steps.length > 1) {
                          const stepUp = steps[1]
                          const newValue = currentValue + stepUp
                          console.log(
                            '+ currentValue',
                            currentValue,
                            'stepUp',
                            stepUp
                          )
                          sliderRef.current.set(newValue)
                        }
                      }}
                    >
                      +
                    </Style.SmallButton>
                  </Style.ExtraSlider>
                </Style.ExtraSliderEntry>
              </Style.Col>
            </Style.Row>
            <Style.Row>
              <Style.Col b={1} />
              <Style.Col>
                <HintButton id='arcpanel.cancel' onClick={_onCancel} />
              </Style.Col>
              <Style.Col b={2}></Style.Col>
              <Style.Col>
                <HintButton id='arcpanel.apply' onClick={_onApply} />
              </Style.Col>
              <Style.Col b={1} />
            </Style.Row>
          </Style.Absolute>
        </Style.Overlay>
      )}
      <Style.Separator />
    </>
  )
}
